import { NodeChange } from '@xyflow/react';
import { HoldingNode } from 'types/entities/holdings';

const Y_THRESHOLD = 120;
const Y_GAP = 240;

const getY = (currentY: number, changeY: number) => {
  if (Math.abs(currentY - changeY) < Y_THRESHOLD) {
    return currentY;
  }

  if (currentY <= 0 && changeY <= 0) {
    return 0;
  }

  if (currentY < changeY) {
    return currentY + Y_GAP;
  }

  return currentY - Y_GAP;
};

export const parseNodeChanges = (changes: NodeChange[], nodes: HoldingNode[]) => {
  return changes.map((change) => {
    if (change.type === 'position') {
      const fixedY = nodes.find((node) => change.id === node.id)?.position.y;

      if (fixedY === undefined) {
        return change;
      }

      if (change.position) {
        change.position = {
          x: change.position.x,
          y: getY(fixedY, change.position.y)
        };
      }

      if (change.positionAbsolute) {
        change.positionAbsolute = {
          x: change.positionAbsolute.x,
          y: getY(fixedY, change.positionAbsolute.y)
        };
      }
    }

    return change;
  });
};
