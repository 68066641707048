import { Edge, Node } from '@xyflow/react';
import { ScenarioNode } from '../types/LCAScenarios';
import { NODE_TYPES } from '../constants';

export const isDeletableEdge = (
  nodes: Node[],
  edges: Edge[],
  nodesMap: Map<string, ScenarioNode>
): boolean => {
  if (nodes.length) {
    return false;
  }

  if (edges?.some((edge) => nodesMap.get(edge.target)?.type === NODE_TYPES.product)) {
    return false;
  }

  return true;
};
