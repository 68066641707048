import {
  Background,
  Controls,
  OnConnectEnd,
  OnEdgesDelete,
  OnNodeDrag,
  OnSelectionChangeFunc,
  ReactFlow
} from '@xyflow/react';
import { withReactFlow } from 'lib/ReactFlow/withReactFlow';
import { DIAGRAM_CONFIG, EDGE_COMPONENTS, NODE_COMPONENTS } from './constants';
import { FitViewBTN } from 'components/ui/canvas';
import { useState } from 'react';
import { useHoldingNodes } from './hooks/useHoldingNodes';
import { checkDeletable } from './utils/checkDeletable';
import { getDeleteKeyCode } from 'utils/canvas/getDeleteKeyCode';
import { useHoldingEdges } from './hooks/useHoldingEdges';
import { useContractParent } from './hooks/useContractParent';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useOrganizationBlocks } from './hooks/useOrganizationBlocks';
import { useMoveOrganizationBlocks } from './hooks/useMoveOrganizationBlock';
import { adapterMoveOrganizationBlocks } from 'adapters/adaptHoldingBlocks';
import { getIsInOrg } from './utils';
import { useHoldingConnections } from './hooks/useHoldingConnections';
import { useCreateConnection } from './hooks/useCreateConnection';
import { useDeleteConnection } from './hooks/useDeleteConnection';
import { ModalManager } from './components/ModalManager';
import { ModalType } from './components/ModalManager/types';
import { validateConnection } from './utils/validateConnection';
import { useNotificationTranslation } from 'customHooks/translations';
import { useNotification } from 'customHooks/useNotification';

export const HoldingDiagram = withReactFlow(() => {
  const [isDeletable, setIsDeletable] = useState(false);
  const [modal, setModal] = useState<ModalType>(null);
  const { moveOrganizationBlocks } = useMoveOrganizationBlocks();
  const { createConnection } = useCreateConnection();
  const { deleteConnection } = useDeleteConnection();
  const org = useSelectedOrganization();
  const { t } = useNotificationTranslation();
  const { notify } = useNotification();

  const organizationId = org?.id;

  const { data: contractOrgId, isLoading: isFetchingContractParentId } =
    useContractParent(organizationId);

  const isInContractOrg = getIsInOrg(contractOrgId, org);

  const { data, isLoading: isFetchingBlocks } = useOrganizationBlocks({
    contractOrgId,
    organizationId
  });

  const { data: holdingConnections, isLoading: isFetchingConnections } = useHoldingConnections({
    contractOrgId,
    organizationId
  });

  const [nodes, _setNodes, onNodesChange, nodesMap] = useHoldingNodes({
    organizationBlocks: data,
    isLoading: isFetchingBlocks || isFetchingContractParentId || isFetchingConnections
  });

  const [edges, _setEdges, onEdgesChange] = useHoldingEdges({
    holdingConnections,
    isLoading: !nodes || isFetchingConnections
  });

  const onSelectionChange: OnSelectionChangeFunc = ({ nodes, edges }) => {
    if (!isInContractOrg) {
      setIsDeletable(false);
      return;
    }

    setIsDeletable(checkDeletable(nodes, edges));
  };

  const onConnectEnd: OnConnectEnd = (_event, { fromNode, toNode, isValid }) => {
    if (!isValid) {
      notify(t('holdings.invalid_connection'));

      return;
    }

    if (!fromNode || !toNode || !isInContractOrg) {
      return;
    }

    createConnection({ source_id: fromNode.id, target_id: toNode.id });
  };

  const onNodeDragStop: OnNodeDrag = async (_event, node) => {
    if (!contractOrgId) {
      return;
    }

    const nodes_to_move = nodes.filter((n) => n.selected || n.id === node.id);

    moveOrganizationBlocks(
      adapterMoveOrganizationBlocks({ contract_org_id: contractOrgId, nodes: nodes_to_move })
    );
  };

  const onEdgesDelete: OnEdgesDelete = (edges) => {
    if (!isInContractOrg) {
      return;
    }

    edges.forEach((edge) => {
      deleteConnection({ source_id: edge.source, target_id: edge.target });
    });
  };

  return (
    <ModalManager.Root modal={modal} setModal={setModal}>
      <ModalManager />
      <div className='flex-grow relative border-box on-card-gray-bg-color card-border-color border-1 border-solid rounded-8'>
        <ReactFlow
          nodeOrigin={DIAGRAM_CONFIG.NODE_ORIGIN}
          minZoom={DIAGRAM_CONFIG.MIN_ZOOM}
          nodeTypes={NODE_COMPONENTS}
          edgeTypes={EDGE_COMPONENTS}
          nodes={nodes}
          onNodesChange={onNodesChange}
          onSelectionChange={onSelectionChange}
          onEdgesChange={onEdgesChange}
          edges={edges}
          onEdgesDelete={onEdgesDelete}
          proOptions={DIAGRAM_CONFIG.PRO_OPTIONS}
          fitView
          snapToGrid
          isValidConnection={validateConnection(nodesMap)}
          snapGrid={DIAGRAM_CONFIG.SNAP_GRID}
          nodeDragThreshold={DIAGRAM_CONFIG.NODE_DRAG_THRESHOLD}
          onConnectEnd={onConnectEnd}
          deleteKeyCode={getDeleteKeyCode(isDeletable)}
          onNodeDragStop={onNodeDragStop}
          nodesDraggable={isInContractOrg}>
          <Controls
            showFitView={false}
            showInteractive={false}
            showZoom={false}
            className='bg-neutral-gray-warm-60 p-2 rounded-8 flex gap-x-4'
            orientation='horizontal'>
            <FitViewBTN />
          </Controls>
          <Background variant={DIAGRAM_CONFIG.BG.VARIANT} gap={DIAGRAM_CONFIG.BG.GAP} />
        </ReactFlow>
      </div>
    </ModalManager.Root>
  );
});
