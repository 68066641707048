import { BaseEdge, Position, getSmoothStepPath } from '@xyflow/react';
import { REM } from 'constants/measures';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  target: string;
  targetPosition: Position;
  markerEnd?: string;
};

export const DefaultEdge: React.FC<Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 6,
    targetX: targetX - 8 * REM,
    targetY,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  return <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />;
};
