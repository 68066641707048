import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { groupByCategoryHolding as groupByCategoryHoldingFunction } from '../../../constants/groupByCategoryFunction';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useTreatmentSector from '../../../customHooks/useTreatmentSector';
import { OrgInfo } from '../../../types/entities/holdings';
import { User } from '../../../types/entities/user';
import { GroupDataMode } from '../../../types/entitiesEnums/report';
import { Column } from '../../../types/table';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import Button from '../../ui/button/Button';
import SlideToggle2 from '../../ui/formComponents/slideToggle2/SlideToggle2';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import { Transform } from './LcaCategories/Transform';
import { Usage } from './LcaCategories/Usage';
import { Waste } from './LcaCategories/Waste';
import BusinessTrips from './businessTrips/BusinessTrips';
import ChildsInfo from './childsInfo/ChildsInfo';
import { useTransformData } from './childsInfo/useTransformData';
import Closing from './closing/Closing';
import Deliveries from './deliveries/Deliveries';
import EmployeesPieChart from './employees/employeePieChart/EmployeesPieChart';
import Employees from './employees/employees/Employees';
import Facilities from './facilities/Facilities';
import FacilitiesDistribution from './facilities/FacilitiesDistribution';
import FrontPage from './frontPage/FrontPage';
import useChildsInfo from './hooks/useChildsInfo';
import useGetData from './hooks/useGetData';
import Impact from './impact/Impact';
import { ImpactScope3 } from './impact/ImpactScope3';
import Investments from './investments/Investments';
import Layout from './layout/Layout';
import Measure from './measure/Measure';
import MeasureMain from './measure/MeasureMain';
import Methodology from './methodology/Methodology';
import Purchases from './purchases/Purchases';
import Shipments from './shipments/Shipments';
import './styles.scss';
import Vehicles from './vehicles/Vehicles';

type TemplateState = {
  auth: {
    user: User;
  };
};

const mapStateToProps = (state: TemplateState) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const DcycleReport = ({ user }: Props) => {
  const { t } = useTranslation();
  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const paperSize = 'auto';
  const flags = useFeatureFlags();
  const groupByCategoryHolding = groupByCategoryHoldingFunction(flags);
  const { groupByCategory: groupByCategoryDirty } = useTreatmentSector();

  const groupByCategory = groupByCategoryDirty.map((elem) => ({
    ...elem,
    category: elem.category
  }));

  const { categoriesColumns: columnsScope1 } = useTransformData(groupByCategory, 1);
  const { categoriesColumns: columnsScope2 } = useTransformData(groupByCategory, 2);
  const { categoriesColumns: columnsScope3 } = useTransformData(groupByCategory, 3);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [categoriesToShow, setCategoriesToShow] = useState<CategoryWithScope[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [groupDataMode, setGroupDataMode] = useState<GroupDataMode>(GroupDataMode.TOTAL);

  const foundOrg = user?.organizations?.find((org) => org.id === user.selectedOrganization);

  const {
    loading,
    dataScopes,
    dataCategories,
    intensityMetric,
    investments,
    organizations,
    facilities,
    vehicles,
    employees,
    businessTravels,
    shipments,
    deliveries,
    purchases,
    employeesLength,
    dataQuality,
    extraInformation,
    dataHolding,
    totalCo2Facilities,
    transform,
    usage,
    waste
  } = useGetData({
    groupDataMode,
    user
  });

  let organizationsList: OrgInfo[] = [];
  if (dataHolding && foundOrg) {
    const { dataHoldingList } = useChildsInfo({
      dataHolding,
      foundOrg
    });
    organizationsList = dataHoldingList;
  }

  useEffect(() => {
    if (user && user.selectedOrganization) {
      const query = new URLSearchParams(window.location.search);
      let start_date: Date | null = null;
      let end_date: Date | null = null;

      if (query.get('start_date') && query.get('end_date')) {
        start_date = new Date(moment(query.get('start_date'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        setStartDate(start_date);
        end_date = new Date(moment(query.get('end_date'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
        setEndDate(end_date);
      }
      const categoriesQuery = query.get('categories');
      if (categoriesQuery) {
        const categoriesQuerySplitted = categoriesQuery.split(',');
        const categoriesToShowMapped = categoriesQuerySplitted.map((category) => {
          const foundCategory = groupByCategoryHolding.find((elem) => {
            const categoryParsed = elem.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '');
            return categoryParsed === category;
          });
          let categoryScope = 0;
          if (foundCategory) {
            categoryScope = foundCategory.scope;
          }
          return {
            category,
            scope: categoryScope
          };
        });
        if (groupDataMode === GroupDataMode.INVESTMENT) {
          categoriesToShowMapped.push({
            category: 'investments',
            scope: 3
          });
        }
        setCategoriesToShow(categoriesToShowMapped);
      }
    }
  }, [user, groupDataMode]);

  const exportPDFWithMethod = () => {
    const element = container.current || document.body;
    setLoadingButton(true);

    const fileName = t('report.downloadName', {
      groupDataMode:
        groupDataMode === GroupDataMode.TOTAL
          ? t('dashboard.consolidated')
          : t('dashboard.investment'),
      // companyName: org_name,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    });
    savePDF(
      element,
      {
        paperSize: paperSize,
        margin: 0,
        fileName,
        forcePageBreak: '.page-break'
      },
      () => {
        setLoadingButton(false);
      }
    );
  };

  const handleSwitchGroupMode = () => {
    if (groupDataMode === GroupDataMode.TOTAL) {
      setGroupDataMode(GroupDataMode.INVESTMENT);
    } else {
      setGroupDataMode(GroupDataMode.TOTAL);
    }
  };

  const MAX_FACILITIES = 4;

  let org_name = '';
  if (foundOrg && foundOrg.company_name) {
    org_name = foundOrg.company_name;
  }
  if (!foundOrg || !startDate || !endDate || checkFreePlan(foundOrg) || !groupDataMode) {
    return null;
  }
  if (loading) {
    return <LoaderTables />;
  }

  const sliceData = (columns: Column[], max: number) => {
    const resultArray: Column[][] = [];

    for (let i = 0; i < (columns.length - 1) / max; i++) {
      resultArray.push(columns.slice(i * max, i * max + max));
    }

    return resultArray;
  };

  const slicedArray = sliceData(columnsScope3, 5);

  const sections = [
    <FrontPage
      key='front-page'
      startDate={startDate}
      endDate={endDate}
      groupDataMode={groupDataMode}
    />,
    <MeasureMain
      groupDataMode={groupDataMode}
      key='measure-main'
      categoriesToShow={categoriesToShow}
    />,
    <Methodology key='methodology' startDate={startDate} endDate={endDate} />,
    <Measure key='measure' />
  ];

  if (dataScopes && dataCategories) {
    sections.push(
      <Impact
        dataScopes={dataScopes}
        dataCategories={dataCategories}
        organization={foundOrg}
        endDate={endDate}
        dataQuality={dataQuality}
        intensityMetric={intensityMetric}
        categoriesToShow={categoriesToShow}
      />
    );
  }

  const categoriesToShowArray = categoriesToShow.map((elem) => elem.category);

  if (dataScopes.filter((elem) => elem.scope === 3)) {
    sections.push(
      <ImpactScope3
        categories={dataCategories.filter((category) =>
          categoriesToShowArray.includes(category.category)
        )}
      />
    );
  }
  if (
    facilities &&
    facilities.length > 0 &&
    categoriesToShow.find(
      (elem) =>
        elem.category === 'stationary' ||
        elem.category === 'recharge' ||
        elem.category === 'wastes' ||
        elem.category === 'electricity' ||
        elem.category === 'water'
    )
  ) {
    facilities.forEach((facility, index) => {
      if (index % MAX_FACILITIES === 0) {
        sections.push(
          <Facilities
            facilities={facilities.slice(index, index + MAX_FACILITIES)}
            organizations={organizations}
            totalCo2={totalCo2Facilities}
            categoriesToShow={categoriesToShow}
          />
        );
      }
    });
    sections.push(
      <FacilitiesDistribution facilities={facilities} categoriesToShow={categoriesToShow} />
    );
  }

  if (vehicles && categoriesToShow.find((elem) => elem.category === 'transport')) {
    sections.push(<Vehicles vehicles={vehicles} />);
  }
  if (employees && categoriesToShow.find((elem) => elem.category === 'employees')) {
    sections.push(
      <Employees employees={employees} extraInformation={extraInformation} />,
      <EmployeesPieChart employees={employees} employeesLength={employeesLength} />
    );
  }
  if (businessTravels && categoriesToShow.find((elem) => elem.category === 'travels')) {
    sections.push(<BusinessTrips businessTravels={businessTravels} />);
  }
  //añadir aquí las categorías 11 y 12
  if (
    shipments &&
    categoriesToShow.find((elem) => elem.category === 'transport_distribution_downstream')
  ) {
    sections.push(<Shipments shipments={shipments} />);
  }
  if (
    deliveries &&
    categoriesToShow.find((elem) => elem.category === 'transport_distribution_upstream')
  ) {
    sections.push(<Deliveries deliveries={deliveries} />);
  }
  if (purchases && categoriesToShow.find((elem) => elem.category === 'purchases')) {
    sections.push(<Purchases purchases={purchases} />);
  }
  if (user.selectedOrganization === user.selectedOrganization && foundOrg.is_group_fund) {
    sections.push(<Investments investments={investments} />);
  }

  if (
    transform &&
    categoriesToShow.find((elem) => elem.category === 'sold_products_intermediate_processing')
  ) {
    sections.push(<Transform data={transform} />);
  }
  if (usage && categoriesToShow.find((elem) => elem.category === 'sold_products_usage')) {
    sections.push(<Usage data={usage} />);
  }
  if (waste && categoriesToShow.find((elem) => elem.category === 'sold_products_waste')) {
    sections.push(<Waste data={waste} />);
  }

  if (
    user.selectedOrganization === user.selectedOrganization &&
    !foundOrg.is_group_fund &&
    dataHolding &&
    organizationsList.length > 0 &&
    groupDataMode == GroupDataMode.TOTAL
  ) {
    const MAX_ORGS = 5;
    organizationsList.forEach((elem, index) => {
      if (index % MAX_ORGS === 0) {
        sections.push(
          <ChildsInfo dataHoldingList={organizationsList.slice(index, index + MAX_ORGS)} />
        );
      }
    });
    organizationsList.forEach((elem, index) => {
      if (index % MAX_ORGS === 0) {
        sections.push(
          <ChildsInfo
            dataHoldingList={organizationsList.slice(index, index + MAX_ORGS)}
            scope={1}
            categoriesColumns={columnsScope1}
          />
        );
      }
    });
    organizationsList.forEach((elem, index) => {
      if (index % MAX_ORGS === 0) {
        sections.push(
          <ChildsInfo
            dataHoldingList={organizationsList.slice(index, index + MAX_ORGS)}
            scope={2}
            categoriesColumns={columnsScope2}
          />
        );
      }
    });

    slicedArray.forEach((column, indexColumn) => {
      organizationsList.forEach((elem, index) => {
        if (index % MAX_ORGS !== 0) return;

        sections.push(
          <ChildsInfo
            dataHoldingList={organizationsList.slice(index, index + MAX_ORGS)}
            scope={3}
            categoriesColumns={column}
            showTotal={indexColumn === slicedArray.length - 1}
            indexSlice={indexColumn + 1}
            lengthData={slicedArray.length}
          />
        );
      });
    });
  }

  sections.push(<Closing />);

  return (
    <div className='dcycle-report' id='dcycle-report'>
      <div className='buttons'>
        <SlideToggle2
          options={[
            {
              id: GroupDataMode.TOTAL,
              name: t('dashboard.consolidated'),
              tooltip: t('dashboard.consolidatedTooltip')
            },
            {
              id: GroupDataMode.INVESTMENT,
              name: t('dashboard.investment'),
              tooltip: t('dashboard.investmentTooltip')
            }
          ]}
          value={{ id: groupDataMode, name: groupDataMode }}
          setValue={handleSwitchGroupMode}
          tooltipPosition='left'
        />
        <Button
          lookAndFeel='primary'
          onClick={exportPDFWithMethod}
          text={t('report.download')}
          loading={loadingButton}
          size='small'
        />
      </div>

      <PDFExport
        ref={pdfExportComponent}
        paperSize={paperSize}
        margin={0}
        fileName={`Report for ${new Date().getFullYear()}`}
        author='Dcycle'
        forcePageBreak='.page-break'>
        <div ref={container}>
          {sections.map((elem, index) => {
            if (index === 0 || index === 2 || index === 3) {
              return (
                <Layout
                  backgroundImage='/images/report/frontpagebg.png'
                  org_name={org_name}
                  newPage={index !== 0}
                  page={index + 1}
                  key={`section-${index}`}
                  total={sections.length}>
                  {elem}
                </Layout>
              );
            }
            if (index === sections.length - 1) {
              return (
                <Layout
                  backgroundImage='/images/report/frontpagebg.png'
                  org_name={''}
                  page={index + 1}
                  key={`section-${index}`}
                  total={sections.length}>
                  {elem}
                </Layout>
              );
            }
            if (elem.type.name === 'ChildsInfoWrapper') {
              return elem;
            }
            return (
              <Layout
                org_name={org_name}
                page={index + 1}
                key={`section-${index}`}
                total={sections.length}>
                {elem}
              </Layout>
            );
          })}
        </div>
      </PDFExport>
    </div>
  );
};

export default connector(DcycleReport);
