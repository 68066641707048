import { InternalNodeBase } from '@xyflow/system';
import { ScenarioNode } from '../types/LCAScenarios';
import { NODE_TYPES } from '../constants';

type Params = {
  toNode: InternalNodeBase;
  fromNode: InternalNodeBase;
};

export const isValidConnection =
  ({ toNode, fromNode }: Params) =>
  (node: ScenarioNode) => {
    if (node.id !== fromNode.id && !node.selected) {
      return false;
    }

    if (node.data.outputs.some((output) => output.id === toNode.id)) {
      return false;
    }

    if (node.type === NODE_TYPES.product) {
      return false;
    }

    return true;
  };
