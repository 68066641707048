import { BaseEdge, EdgeLabelRenderer, Position, getSmoothStepPath } from '@xyflow/react';
import { REM } from 'constants/measures';
import type { HoldingEdgeData } from 'types/entities/holdings';
import { MODAL_NAMES, useModalManager } from '../ModalManager';
import Button from 'components/ui/button/Button';
import { useState } from 'react';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  targetPosition: Position;
  data: HoldingEdgeData;
  markerEnd?: string;
};

export const ShareEdge: React.FC<Props> = ({
  id,
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const { openModal } = useModalManager();
  const [isHighlighted, setIsHighlighted] = useState(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 6,
    targetX: targetX - 8 * REM,
    targetY,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  if (data.share === null) {
    return null;
  }

  const onClickShare: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();

    openModal(MODAL_NAMES.SHARE, id);
  };

  const edgeClassNames = [];

  if (isHighlighted) {
    edgeClassNames.push('stroke-secondary-purple-20 stroke-width-3px z-10');
  }

  const onMouseEnter = () => {
    setIsHighlighted(true);
  };

  const onMouseLeave = () => {
    setIsHighlighted(false);
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        className={edgeClassNames.join(' ')}
      />
      <EdgeLabelRenderer>
        <Button
          lookAndFeel='secondary'
          size='tiny'
          text={`${data.share * 100}%`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClickShare}
          style={{
            width: 'fit-content',
            pointerEvents: 'all',
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
          }}
          className='nodrag nopan'
        />
      </EdgeLabelRenderer>
    </>
  );
};
