export const environment = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_q5eATMvsJ',
      userPoolWebClientId: '5kd0fhjvqj74hvtpd1rcie7bva',
      oauth: {
        domain: 'dcycle-backend-auth-sandbox-vincorreia-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://autonomy-app.dcycle.io/callback',
        redirectSignOut: 'https://autonomy-app.dcycle.io/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.sandboxgonzalojman.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'https://autonomy-app.dcycle.io/'
};
