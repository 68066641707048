import { Dispatch, SetStateAction, useState } from 'react';
import { Facility } from '../../../../../../types/entities/facility';
import {
  InvoiceAuto,
  InvoiceToUpload,
  InvoiceType,
  Percentages
} from '../../../../../../types/entities/invoice';
import SelectInvoiceType from '../createInvoice/components/common/selectInvoiceType/SelectInvoiceType';
import './styles.scss';
import PDFViewer from './common/pdfViewer/PDFViewer';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import UploadWasteInvoices from './uploadWasteInvoices/UploadWasteInvoices';
import UploadHeatInvoices from './uploadHeatInvoices/UploadHeatInvoices';
import UploadElectricityInvoices from './uploadElectricityInvoices/UploadElectricityInvoices';
import UploadWaterInvoices from './uploadWaterInvoices/UploadWaterInvoices';

type Props = {
  setInvoiceMode?: Dispatch<SetStateAction<string>>;
  addInvoice: (invoicesUploaded: InvoiceAuto[]) => void;
  addInvoiceWaste: (invoiceUploaded: InvoiceType) => void;
  facility: Facility;
  defaultType: string;
};

export type FormDataUploadInvoices = {
  supplier: SelectOptionFormat;
  toc?: SelectOptionFormat;
  facilityFuel: SelectOptionFormat;
  rdCode: SelectOptionFormat;
  percentages: Percentages[];
  type: string;
  errors: ErrorType[];
};

const UploadInvoices = ({
  addInvoice,
  addInvoiceWaste,
  setInvoiceMode,
  facility,
  defaultType
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const getType = () => {
    let type = defaultType;
    if (facility.categories.length === 1) {
      type = facility.categories[0];
    }
    type = defaultType === 'recharge' ? facility.categories[0] : defaultType;

    if (type === 'wastes') {
      type = 'waste';
    }
    return type;
  };

  const selectedOrganization = useSelectedOrganization();
  const [documentsToUpload, setDocumentsToUpload] = useState<InvoiceToUpload[]>([]);
  const [formData, setFormData] = useState<FormDataUploadInvoices>({
    supplier: { id: '', name: '' },
    facilityFuel: { id: '', name: '' },
    percentages: [
      {
        facility: { id: facility.id, name: facility.name },
        organization: {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        },
        percentage: '100'
      }
    ],
    rdCode: { id: '', name: '' },
    type: getType(),
    errors: []
  });

  return (
    <div className='flex'>
      <PDFViewer documentsToUpload={documentsToUpload} />
      <div className='upload-invoice'>
        <FormHeader title={t('uploadNewInvoice')} />
        <SelectInvoiceType
          mode={'auto'}
          action={'create'}
          onClickCard={(id) => {
            setFormData((prev) => ({ ...prev, type: id }));
          }}
          type={formData.type}
          facilityType={facility.facility_purpose_type}
          facilityCategories={facility.categories}
        />
        {formData.type === 'heat' && (
          <UploadHeatInvoices
            facility={facility}
            formData={formData}
            setFormData={setFormData}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            addInvoice={addInvoice}
          />
        )}
        {formData.type === 'electricity' && (
          <UploadElectricityInvoices
            facility={facility}
            formData={formData}
            setFormData={setFormData}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            addInvoice={addInvoice}
          />
        )}
        {formData.type === 'water' && (
          <UploadWaterInvoices
            facility={facility}
            formData={formData}
            setFormData={setFormData}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            addInvoice={addInvoice}
          />
        )}
        {formData.type === 'waste' && (
          <UploadWasteInvoices
            facility={facility}
            formData={formData}
            setFormData={setFormData}
            documentsToUpload={documentsToUpload}
            setDocumentsToUpload={setDocumentsToUpload}
            handleClose={() => setInvoiceMode && setInvoiceMode('')}
            addInvoice={addInvoiceWaste}
          />
        )}
      </div>
    </div>
  );
};

export default UploadInvoices;
