import { Handle, Position } from '@xyflow/react';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { ScenarioNode } from '../../types/LCAScenarios';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { useParams } from 'react-router-dom';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { useLCAMaterial } from '../../hooks/useLCAMaterial';
import { Toolbar } from './Toolbar';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import { DeleteNodeActionBTN } from './ActionButtons/DeleteNodeActionBTN';
import { MATERIAL_DELETE_TOOLTIP_TEXT } from './constants';
import { useModalContext } from '../ModalHandler';
import { CustomNode } from './CustomNode';
import { getHandleStyle } from './utils';
import { useHover } from 'customHooks/useHover';

const getDeleteState = (hasConnections: boolean): keyof typeof MATERIAL_DELETE_TOOLTIP_TEXT => {
  if (hasConnections) {
    return 'has_connections';
  }

  return 'default';
};

type MaterialNodeProps = {
  data: ScenarioNode['data'];
  selected?: boolean;
};

export const MaterialNode: React.FC<MaterialNodeProps> = ({ data, selected }) => {
  const { t } = useLCATranslation();

  const { setModalData } = useModalContext();

  const { id: acv_id } = useParams<{ id: string }>();

  const nodeMetadata = useLCAMaterial({ acv_id: acv_id ?? 'none', node_id: data.id });

  const materials = nodeMetadata?.entity_id ?? [];

  const { isHovered, handleMouseEnter, handleMouseLeave, nodeRef } = useHover();

  const hasConnections = data.outputs?.length > 0 || data.inputs?.length > 0;

  const deleteState = getDeleteState(hasConnections);

  const handleClick = () => {
    setModalData({
      type: 'edit_material',
      target: data.id
    });
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={nodeRef}>
      <CustomNode.Layout selected={selected}>
        <div className='flex-col gap-y-4 min-w-48'>
          <CustomNode.Content>
            <CustomNode.Icon
              icon={NODE_ICONS[NODE_TYPES.material]}
              lookAndFeel='material'
              disabled={!materials.length}
            />
            <div className='flex-col'>
              <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.material')}</span>
              <span className='font-body-b1-sb'>{nodeMetadata?.name ?? '-'}</span>
            </div>
          </CustomNode.Content>
          {Boolean(data.tags?.length) && (
            <div className='flex gap-x-2'>
              {data.tags?.map((tag) => (
                <CustomNode.Tag key={data.id + tag} disabled>
                  {tag}
                </CustomNode.Tag>
              ))}
            </div>
          )}

          {!materials.length && (
            <CustomNode.Tag disabled>
              <Icon icon='warning' color='disabled' size='medium' />
              {t('lcaScenarios.noMaterial')}
            </CustomNode.Tag>
          )}
        </div>
        {
          <Button
            lookAndFeel={!materials.length ? 'primary' : 'secondary'}
            size='small'
            text={t(!materials.length ? 'lcaScenarios.addMaterial' : 'lcaScenarios.editMaterial')}
            iconNode={
              !materials.length ? (
                <Icon icon='add' color='white' size='medium' />
              ) : (
                <Icon icon='edit' color='gray-dark' size='medium' />
              )
            }
            onClick={handleClick}
          />
        }
      </CustomNode.Layout>
      <Handle
        type='source'
        position={Position.Right}
        isConnectableStart={data.outputs?.length === 0}
        className='custom-source-handle'
        style={getHandleStyle('source', data.outputs?.length > 0)}
      />
      {isHovered && (
        <Toolbar.Layout>
          <TooltipWrapper text={t(MATERIAL_DELETE_TOOLTIP_TEXT[deleteState])}>
            <DeleteNodeActionBTN acv_id={acv_id} node_id={data.id} disabled={hasConnections} />
          </TooltipWrapper>
        </Toolbar.Layout>
      )}
    </div>
  );
};
