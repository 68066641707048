import type {
  OrganizationFormValues,
  PayloadCreateOrUpdateOrganization,
  ResponseGetUsedEntities,
  UsedEntity
} from 'types/entities/holdings';
import { adapterEntityType } from './adaptEntityType';

type AdapterCreateOrganizationParams = {
  form: OrganizationFormValues;
  organization_id: string;
  contract_org_id: string;
  node_id: string;
};

export const adapterCreateOrganization = ({
  form,
  organization_id,
  contract_org_id,
  node_id
}: AdapterCreateOrganizationParams): PayloadCreateOrUpdateOrganization => {
  return {
    canvas_organization_id: contract_org_id,
    organization_id: organization_id || null,
    node_id: node_id,
    company_name: form.name,
    cif: form.cif,
    country: form.country,
    sector: form.sector,
    limit_facilities: parseInt(form.limit_facilities),
    limit_vehicles: parseInt(form.limit_vehicles),
    limit_employees: parseInt(form.limit_employees)
  };
};

export const adapterUsedEntities = (data: ResponseGetUsedEntities): UsedEntity[] => {
  return data
    .map((entity) => ({
      limit: adapterEntityType(entity.limit),
      totalUsed: entity.total_used,
      totalAvailable: entity.total_available,
      total: entity.total_available + entity.total_used
    }))
    .sort((entity) => {
      if (entity.limit === 'facilities') {
        return -2;
      }

      if (entity.limit === 'vehicles') {
        return -1;
      }

      return 0;
    });
};
