import { ReactComponent as EUTaxonomyES } from 'assets/img/eu_taxonomy_es.svg';
import { ReactComponent as EUTaxonomyEN } from 'assets/img/eu_taxonomy_en.svg';
import { ReactComponent as DMES } from 'assets/img/double_materiality_image_es.svg';
import { ReactComponent as DMEN } from 'assets/img/double_materiality_image_en.svg';
import InfographyES from 'assets/img/info_es.png';
import InfographyEN from 'assets/img/info_en.png';

export const PNGS = {
  es: {
    EUTaxonomy: EUTaxonomyES,
    DM: DMES,
    Infography: InfographyES
  },
  en: {
    EUTaxonomy: EUTaxonomyEN,
    DM: DMEN,
    Infography: InfographyEN
  }
} as const;

export const STEPS_SOCIAL = {
  WORKERS_IN_THE_VALUE_CHAIN: 'workers_in_the_value_chain',
  CONSUMERS_AND_END_USERS: 'consumers_and_end-users',
  AFFECTED_COMMUNITIES: 'affected_communities',
  OWN_WORKFORCE: 'own_workforce'
} as const;
