import moment from 'moment';
import { PercentagesBackend } from '../../types/entities/invoice';
import {
  FormDataWaste,
  IWasteInvoiceBack,
  PostTreatmentBody,
  UpdateWasteBody
} from '../../types/entities/waste';
import apiFetch from '../../utils/apiFetch';

type LowCodesBody = {
  returnRequest: boolean;
  waste_region?: string;
};

export const getLowCodes = async ({ waste_region = 'GLO', returnRequest }: LowCodesBody) => {
  try {
    const request = apiFetch('GET', `/wastes/low_codes?waste_region=${waste_region}`, null, {
      waste_region
    });
    if (returnRequest) return request;
    return (await request).data;
  } catch (error) {
    return error;
  }
};

type RdCodesBody = {
  lowId?: string;
  returnRequest?: boolean;
};

export const getRdCodesByLowId = async ({ lowId, returnRequest = false }: RdCodesBody) => {
  try {
    const params: {
      low_id?: string;
    } = {};

    if (lowId) {
      params.low_id = lowId;
    }
    const request = apiFetch('GET', `/wastes/rd_codes`, null, {}, params);
    if (returnRequest) return request;
    return (await request).data;
  } catch (error) {
    return error;
  }
};

export const getWasteById = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/wastes/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postWaste = async (body: PostTreatmentBody) => {
  try {
    const response = await apiFetch('POST', `/wastes`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

type PostWasteCustomBody = {
  file_url: string;
  identification_name: string;
  quantity: string;
  unit_id: string;
  total_km_to_waste_center?: number;
  destination?: string;
  start_date: string;
  end_date: string;
  uploaded_by: string;
  facility_id: string;
  status: string;
  organization_id: string;
  custom_emission_factor_id: string;
  facility_percentages: PercentagesBackend[];
};
export const postWasteCustom = async (
  body: FormDataWaste,
  organization_id: string,
  facility_id: string,
  uploaded_by: string
) => {
  const bodyFormated: PostWasteCustomBody = {
    file_url: '',
    identification_name: body.optionalId,
    quantity: body.quantity,
    unit_id: body.unitCustom.id,
    total_km_to_waste_center: body.totalKm ? Number(body.totalKm) : undefined,
    destination: body.destination,
    start_date: moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    uploaded_by,
    facility_id,
    status: 'uploaded',
    organization_id,
    custom_emission_factor_id: body.emissionFactor ?? '',
    facility_percentages: body.percentages.map((elem) => ({
      facility_id: elem.facility.id,
      organization_id: elem.organization.id,
      percentage: parseFloat(elem.percentage) / 100
    }))
  };

  try {
    const response = await apiFetch('POST', '/wastes/custom_emission_factor', bodyFormated);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateWaste = async (id: string, body: UpdateWasteBody) => {
  try {
    const response = await apiFetch('PUT', `/wastes/${id}`, body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteWaste = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/wastes/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type PostWasteFile = {
  file_name: string;
  facility_id: string;
  uploaded_by: string;
  waste_data: string;
};

export const postWasteFile = async (organization_id: string, formData: PostWasteFile) => {
  try {
    const response = await apiFetch('POST', '/wastes/upload', formData, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPresignedUrlWaste = async (file_name: string) => {
  try {
    const body = {
      file_name
    };

    const response = await apiFetch('POST', '/wastes/bulk', body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getRdLerList = async () => {
  try {
    const response = await apiFetch('GET', '/wastes/rd_ler_list');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadWasteWizard = async (data: IWasteInvoiceBack) => {
  try {
    const response = await apiFetch('POST', '/wastes/wizard', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadWasteInvoices = async (
  file: File,
  facility_id: string,
  supplier: string,
  rd_code?: string
) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    formData.append('supplier', supplier);

    if (rd_code) {
      formData.append('rd_code', rd_code);
    }
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    const response = await apiFetch(
      'POST',
      `/v1/facilities/${facility_id}/wastes/upload`,
      formData,
      headers
    );
    return response.data;
  } catch (error: any) {
    const error_code = error.response?.data ?? null;
    return error_code;
  }
};

export const getSuppliers = async () => {
  try {
    const response = await apiFetch('GET', '/wastes/suppliers');
    return response.data;
  } catch (error) {
    return error;
  }
};
